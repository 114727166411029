import axios from "axios"
import {config} from "../../../util/configs"

export function constructNonUkHcpContactFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
            <h3>Here is the response from the submitted form:</h3>
            <div>
                <p><strong>Name</strong>: ${data.name}</p>
                <p><strong>Email</strong>: ${data.email}</p>
                <p><strong>Phone</strong>: (${data.phoneCode}) ${data.phone}</p> 
                <p><strong>Company</strong>: ${data.company}</p>
                <p><strong>Country</strong>: ${data.country}</p>
                <p><strong>How they heard about Diurnal</strong>: ${data.whoReferred}</p>
                <p><strong>Message</strong>: ${data.message}</p>
            </div>
        `
    )
}

export async function submitContactFormNonUkHcp(data){
    return await axios.post('/api/contact-form-mailer', data);
}

export function constructNonUkHcpApplicationFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
            <h3>Here is the response from the submitted form:</h3>
            <div>
                <p><strong>Name</strong>: ${data.name}</p>
                <p><strong>Email</strong>: ${data.email}</p>
                <p><strong>Phone</strong>: ${data.phone}</p>
                <p><strong>Message / Cover Letter</strong>: ${data.coverLetter}</p>
            </div>
        `
    )
}

export function constructNonUkHcpEmailFriendFormEmailHtml(data){
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
        <h3>Hi ${data.recipientName}, here is an open vacancy you might be interested in at Diurnal, recommended by ${data.senderEmail}:</h3>
            <div>
                <p><strong>Job Title</strong>: ${data.jobTitle}</p>
                <p><strong>Job Type</strong>: ${data.jobType}</p>
                <p><strong>Country</strong>: ${data.country}</p>
                <p><strong>Location</strong>: ${data.location}</p>
                <a href="${data.jobLink}">Apply Now</a>
            </div>
        `
    )
}

export async function submitApplicationFormNonUkHcp(data){
    return await axios.post("/api/career-applications-mailer", {
        subject: data.subject,
        html: data.html,
        attachment: data.attachment
    });

}

export async function submitEmailFriendFormNonUkHcp(data){
    return await axios.post("/api/email-friend", data);
}

export function constructNonUkHcpAwardsApplicationFormEmailHtml(data){
    let intendedCongress = Object.keys(data.intendedCongress).filter(key => (data.intendedCongress[`${key}`]))[0];
    return (
        `
        <img src="${config.logoUrl}" title="Diurnal Logo" alt="Diurnal Logo" width="100" height="auto" style="display:block; padding-bottom: 15px;" >
            <h3>Here is the response from the submitted form:</h3>
            <div>
                <p><strong>Name</strong>: ${data.name}</p>
                <p><strong>Role</strong>: ${data.role}</p>
                <p><strong>Institution</strong>: ${data.institution}</p>
                <p><strong>Address</strong>: ${data.address}</p>
                <p><strong>Telephone Number</strong>: ${data.phone}</p>
                <p><strong>Email</strong>: ${data.email}</p>
                <p><strong>Intended Congress</strong>: ${intendedCongress}</p>
                <p><strong>Meets the eligibility criteria for the Early Career Investigator Award and, if successful, is available to attend for the duration of the congress</strong>: ${data.eligibilityConfirmation ? "Yes":"No"}</p>
                <p><strong>Indicated consent for Diurnal to store their personal data</strong>: ${data.personalDataConsent ? "Yes": "No"}</p>
                <p><strong>Indicated consent for any award being disclosed publicly by Diurnal</strong>: ${data.publicAwardDisclosal ? "Yes": "No"}</p>
            </div>
        `
    )
}

export async function submitAwardApplicationFormNonUkHcp(data){
    return await axios.post("/api/awards-application-mailer", {
        subject: data.subject,
        html: data.html,
        cv: data.attachment.cv,
        abstract: data.attachment.abstract
    })
}